/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function () {
				// JavaScript to be fired on all pages
						
				
				/* WOW.JS / Animate.css */
				// Fade on page exit.
				// Works for Firefox & Chrome
				
				/*
				window.addEventListener('beforeunload', function () {
					$('#body-wrap').addClass('fadeOut');
				});
				*/
				function addUnload() {
					$('#body-wrap').addClass('fadeOut');
				}
				function removeUnload() {
					$('#body-wrap').removeClass('fadeOut');
				}
				function addBeforeUnload() {
					window.addEventListener('beforeunload', addUnload, false);
				}
				function removeBeforeUnload() {
					window.removeEventListener('beforeunload', addUnload, false);
				}
				addBeforeUnload();
				
				/* Fix for operations that stay on page in window process */
				$('a[href^="tel:"], a[href^="fax:"], a[href^="skype:"], a[href^="mailto:"]').click(function (e) {
					setTimeout(removeUnload, 250);
				});
				/* ------ */
								
								
				/* Smooth-Scroll */
				$('[data-target]').click(function (e) {
					e.preventDefault();
					var target = $(this).attr('data-target');
					$('html, body').animate({scrollTop: $(target).offset().top - 60}, 600);
				});
				/* ------ */
				
				
				/* Click-Element */
				$('div[href], tr[href], p[href], li[href]').click(function () {
					window.location.href = $(this).attr('href');
				});
				/* ------ */


				/* Tooltips */
				$('[data-toggle="tooltip"]').tooltip();
				/* ------ */
				
				
				/* Cookies */
				function setCookie(c_name, value, exdays) {
					var exdate = new Date(),
						c_value;
					exdate.setDate(exdate.getDate() + exdays);
					c_value = encodeURI(value) + ((exdays === null) ? "" : "; expires=" + exdate.toUTCString());
					document.cookie = c_name + "=" + c_value + "; path=/";
				}
				function getCookie(cname) {
					var name = cname + "=",
						decodedCookie = decodeURIComponent(document.cookie),
						ca = decodedCookie.split(';'),
						i,
						c;
					for (i = 0; i < ca.length; i++) {
						c = ca[i];
						while (c.charAt(0) === ' ') {
							c = c.substring(1);
						}
						if (c.indexOf(name) === 0) {
							return c.substring(name.length, c.length);
						}
					}
					return "";
				}
				/* ------ */

				
				/* Text Size - Accessibility Widget */
				function resizeText(multiplier) {
					var fontSize = getCookie("fontSize");
					if (document.body.style.fontSize === "") {
						if (fontSize === "") {
							document.body.style.fontSize = "1.6em";
						} else {
							document.body.style.fontSize = fontSize;
						}
					}
					if (multiplier === "") {
						multiplier = 0;
					}
					document.body.style.fontSize = parseFloat(document.body.style.fontSize) + (multiplier * 0.2) + "em";
					setCookie("fontSize", document.body.style.fontSize, "7");
					//setTimeout(setHeaderOffset, 500);
				}
				$('.btn-increase-text').click(function () {
					resizeText(1);
				});
				$('.btn-decrease-text').click(function () {
					resizeText(-1);
				});
				resizeText();
				/* --- */
				
				
				/* Focus to search box on .btn-search click */
				$('.btn-search').click(function (e) {
					setTimeout(function () { $('input.search-field').focus(); }, 1000);
				});
				/* ---- */
								
								
				/* HEADER STICKY CONSTANT */
				// affix the navbar on scroll
				var header = $('.header-wrapper'),
					navbar = $('#main-nav.sticky'),
					$window = $(window);
				$window.scroll(function () {
					if ($(window).innerWidth() > 991 && $window.scrollTop() > 350) {
						navbar.addClass('navbar-fixed-top').addClass('slideInDown').removeClass('slideOutUp');
					} else if ($(window).innerWidth() > 991 && $window.scrollTop() > 100 && $window.scrollTop() < 349) {
						navbar.removeClass('slideInDown').addClass('slideOutUp');
					} else {
						navbar.removeClass('navbar-fixed-top slideInDown slideOutUp');
					}
				});
				
				/*
				function setHeaderOffset() {
					if ($(window).innerWidth() > 991) {
						$('#body-wrap').css('top', navbar.innerHeight());
					} else {
						$('#body-wrap').css('top', 0);
					}
				}
				setHeaderOffset();
				$(window).resize(function () {
					setHeaderOffset();
				});
				*/
				/* ------ */
				
				
				/* Responsive Nav - Slide-Out Menu */
				function setMenuHeight() {
					var menu = $('.responsive-navigation'),
						mobileMenu = $('.mobile-navigation'),
						primaryMenu = $('.primary-navigation'),
						secondaryMenu = $('.secondary-navigation'),
						quicklinksMenu = $('.quicklinks-navigation'),
						headerHeight = $('.header-wrapper').innerHeight(),
						topNavHeight = $('.top-head-wrapper').innerHeight();
					
					if ($(window).innerWidth() <= 991) {
						// Adjust Menu Height on Resize
						menu.css('min-height', $(document).height() - headerHeight + "px");
						quicklinksMenu.css('min-height', $(document).height() - topNavHeight + "px");
						// Set Menu Top Offset
						mobileMenu.css('top', headerHeight + "px");
						primaryMenu.css('top', headerHeight + "px");
						secondaryMenu.css('top', headerHeight + "px");
						quicklinksMenu.css('top', topNavHeight + "px");
					} else {
						menu.css('min-height', "100%");
						primaryMenu.css('top', 0);
						secondaryMenu.css('top', 0);
						quicklinksMenu.css('top', 0);
					}
				}
				setMenuHeight();
				$(window).resize(function () {
					setMenuHeight();
				});
				/* ------ */
							
							
				/* Home Carousel - Full Screen */
				function setHomeCarouselscreen() {
					var windowHeight = $(window).innerHeight(),
						headerHeight = $('header#header').innerHeight();
					$('#homeCarousel .cover-image').css('min-height', windowHeight - 360);
					if (windowHeight <= 760) {
						$('#homeCarousel .cover-image').css('min-height', 400);
					}
				}
				setHomeCarouselscreen();
				$(window).resize(function () {
					setHomeCarouselscreen();
				});
				/* ------ */
							
								
				/* FULLSCREEN HEIGHT */
				function setFullscreen() {
					var windowHeight = $(window).innerHeight();
					$('.fullscreen').css('min-height', windowHeight);
				}
				setFullscreen();
				$(window).resize(function () {
					setFullscreen();
				});
				/* ------ */
				
				
				/* Sidebar Nav Collapse */
				function sidebarCollapse() {
					if ($(window).width() <= 991) {
						$('#sidebar-nav').collapse('hide');
					} else {
						$('#sidebar-nav').collapse('show');
					}
				}
				$(window).on('resize', function () {
					sidebarCollapse();
				});
				sidebarCollapse();
				/* ------ */
				
				
				/* Slick Featured News
				$('.feature-children-slick').slick({
					vertical: false,
					rows: 1,
					arrows: true,
					dots: false,
					infinite: false,
					speed: 1000,
					slidesToShow: 3,
					slidesToScroll: 1,
					swipe: true,
					swipeToSlide: true,
					lazyLoad: 'ondemand',
					responsive: [{
						breakpoint: 1024,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 1,
							arrows: false,
							dots: true
						}
					}, {
						breakpoint: 762,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1,
							arrows: false,
							dots: true,
							cssEase: 'easeOutElastic' //linear
						}
					}]
					// You can unslick at a given breakpoint now by adding:
					// settings: "unslick"
					// instead of a settings object
				});
				*/
				/* ------ */
				
				
			},
			finalize: function () {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},
		// Home page
		'home': {
			init: function () {
				// JavaScript to be fired on the home page
								
								
				/* Home Carousel */
				$('.feature-video-play').click(function () {
					$('#homeCarousel').carousel('pause');
				});
				/* ------ */
				
				/* Bootstrap Carousel Responsive Slide Visibility */
				function deviceWidth() {
					return $(window).width();
				}
							
				function toggleMobileSlideVisibility(show_hide) {
					var no_mobile_slides,
						reset_active_slide;
					no_mobile_slides = $('.carousel-inner').find('.no-mobile');
					if (show_hide === 'hide') {
						reset_active_slide = false;
						no_mobile_slides.each(function (i, e) {
							if ($(e).hasClass('active')) {
								reset_active_slide = true;
								$(e).removeClass('active');
							}
						});
						no_mobile_slides.removeClass('item').addClass('hide');
						if (reset_active_slide) {
							$('.carousel-inner').find('.item').first().addClass('active');
						}
					} else if (show_hide === 'show') {
						no_mobile_slides.addClass('item').removeClass('hide');
					}
				}
								
				var is_mobile_device = false,
					detectMobile = function detectMobile() {
						if (deviceWidth() > 978) {
							if (is_mobile_device) { toggleMobileSlideVisibility('show'); }
							is_mobile_device = false;
						} else {
							if (!is_mobile_device) { toggleMobileSlideVisibility('hide'); }
							is_mobile_device = true;
						}
					};
				
				$(window).on('resize', function () {
					detectMobile();
				});
							
				detectMobile();
				/* ------ */
				
				
			},
			finalize: function () {
				// JavaScript to be fired on the home page, after the init JS
			
			}
		},
		// About us page, note the change from about-us to about_us.
		'about_us': {
			init: function () {
				// JavaScript to be fired on the about us page
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function (func, funcname, args) {
			var fire,
				namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function () {
			// Fire common init JS
			UTIL.fire('common');
			
			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});
			
			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};
	
	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.